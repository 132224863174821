import { TextField } from '@mui/material'
import { ImageText } from './ImageTextField'

export default function FreeformQuestion({ setAnswer, question, answerKey }) {
  return (
    <>
      <div style={{ padding: '10px' }}>
        <div style={{ padding: '10px' }}>
          <h3>{question.pointValue} points</h3>
          <ImageText
            image={question.imageInfo ? question.imageInfo.image : null}
            text={question.prompt}
            isHeading={true}
            position={question.imageInfo ? question.imageInfo.position : null}
            imageComp={{ type: 'display' }}
          />
        </div>
        <TextField
          id={`${answerKey}AnswerTextField`}
          multiline
          rows={8}
          sx={{ width: '100%' }}
          label='Enter answer here...'
          onChange={e => setAnswer(answerKey, e.target.value)}
        />
      </div>
    </>
  )
}
