export const localEnv = {
  runMode: 'dev',
  clientId: 'df84607a-cbc3-4716-bb67-88929ca220a2',
  urls: {
    examsURL: 'http://localhost:5071',
    // examsURL: 'https://certificationexams.wtxdev.com',
    userExamURL: 'https://userexam.wtxdev.com',
    gatekeeperURL: 'https://wtx-gatekeeper-rest.wtxdev.com'
    // gatekeeperURL: 'http://localhost:5001'
  },
  basicAuthentication: {}
}
