import {
  SlideUpDialog,
  RegularButton,
  PrimaryButton,
  SnackbarVariants,
  CustomAccordion,
  ListSelector
} from '@wavetronix/common-components'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import EmailApi from '../../api/EmailApi'
import CertificationExamsApi from '../../api/CertificationExamsApi'
import RecipientList from '../RecipientList'
import UsersApi from '../../api/UsersApi'
import { useSnackbar } from 'notistack'
import { env } from '../../index.js'
import { DEFAULT_EXAM_FILTER } from '../drawers/ExamFilterDrawer.jsx'
import { Card, TextField } from '@mui/material'
import { filterExams } from '../drawers/ExamFilterDrawer.jsx'

const styles = {
  dataGrid: {
    '& .MuiDataGrid-columnHeaderTitle': {
      fontFamily: 'Klavika',
      fontSize: '16px'
    }
  }
}

export default function IssueExamModal(props) {
  const [selectedExam, setSelectedExam] = useState({})

  const { instance, accounts } = useMsal()

  const { data, isLoading } = useQuery('exams', async () => await CertificationExamsApi.getExams(instance, accounts))

  const { data: nexusUser } = useQuery('users/me', async () => await UsersApi.getMe(instance, accounts))

  const { enqueueSnackbar } = useSnackbar()

  const [disabled, setDisabled] = useState(false)

  const [filter, setFilter] = useState(DEFAULT_EXAM_FILTER)

  let recipientList = props.recipientList
  const columns = [
    {
      field: 'name',
      headerName: 'Exam Name',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      disableColumnMenu: true
    }
  ]

  const sendEmail = async email => {
    let records = await CertificationExamsApi.getUserExamRecords(instance, accounts)
    let result = records.filter(record => {
      if (record.userId === nexusUser.id && record.examId === selectedExam.id) {
        return true
      } else return false
    })
    if (result.length > 0) {
      let newRecord = { ...result[0], attemptsRemaining: 3 }
      CertificationExamsApi.updateRecord(instance, accounts, newRecord)
    }
    await EmailApi.issueExam(instance, accounts, email).then(
      _ => {
        enqueueSnackbar('Successfully issued exam via email', SnackbarVariants.SUCCESS)
        setSelectedExam({})
        props.onClose()
        setDisabled(false)
      },
      error => {
        console.log(error)
        enqueueSnackbar(`Failed to issue exam`, SnackbarVariants.ERROR)
      }
    )
  }

  return (
    <>
      <SlideUpDialog
        id='issueExamModal'
        sx={{ height: '60%' }}
        maxWidth={'md'}
        open={props.open}
        onClose={() => {
          setSelectedExam({})
          props.onClose()
          setDisabled(false)
        }}
        title={<h3 style={{ margin: '0px' }}>Select Exam</h3>}
        children={
          <>
            <div style={{ margin: 10 }}>
              <div style={{ width: '40%', height: '100%', marginLeft: 20, float: 'right' }}>
                <Card sx={{ marginBottom: '10px' }}>
                  <div style={{ margin: '20px' }}>
                    <h4>Filter Exam</h4>
                    <TextField
                      id='ExamNameFilterTextField'
                      label='Name'
                      size='small'
                      variant='outlined'
                      style={{ width: '100%' }}
                      onChange={e => setFilter(f => ({ ...f, name: e.target.value }))}
                      value={filter.name ? filter.name : ''}
                    />
                    <CustomAccordion
                      id='ExamCategoryFilterSelect'
                      title='Category'
                      style={{
                        marginTop: '15px',
                        borderRadius: '5px',
                        width: '100%',
                        boxShadow: 'none',
                        border: '1px solid rgb(196, 196, 196)',
                        '&:before': {
                          display: 'none'
                        }
                      }}
                      titleStyle={{ marginRight: '-9px' }}
                      detailStyle={{ padding: '0px', margin: '0px', marginTop: '0px' }}
                      label='Category'
                      children={
                        <ListSelector
                          selectedOptions={filter.category}
                          options={[
                            { id: 'Test', value: 'Test' },
                            { id: 'Expanse', value: 'Expanse' },
                            { id: 'Legacy', value: 'Legacy' }
                          ]}
                          onChange={e => setFilter(f => ({ ...f, category: [...e] }))}
                        />
                      }
                    />
                  </div>
                </Card>
                <RecipientList recipientList={recipientList ? recipientList.join('\n') : 'No Recipients'}></RecipientList>
              </div>
              <DataGrid
                sx={styles.dataGrid}
                autoHeight
                columns={columns}
                rows={data ? filterExams(filter, data) : []}
                isLoading={isLoading}
                onRowClick={rowInfo => {
                  setSelectedExam(rowInfo.row)
                }}
              />
            </div>
          </>
        }
        actions={
          <>
            <RegularButton
              id='issueExamCancelButton'
              onClick={() => {
                setSelectedExam({})
                props.onClose()
                setDisabled(false)
              }}
              style={{ border: '2px solid red', color: 'red' }}
            >
              Cancel
            </RegularButton>
            <PrimaryButton
              id='issueExamSubmitButton'
              disabled={Object.values(selectedExam).length === 0 || disabled}
              onClick={() => {
                // make Mailjet request via EmailApi
                setDisabled(true)
                sendEmail({
                  issuerName: nexusUser ? `${nexusUser.givenName} ${nexusUser.surname}` : 'A Wavetronix trainer',
                  examName: selectedExam.name,
                  examLink: `${env.urls.userExamURL}/?id=${selectedExam.id}&issuer=${nexusUser.id}`,
                  sendTo: recipientList
                })
              }}
            >
              Issue Exam
            </PrimaryButton>
          </>
        }
      ></SlideUpDialog>
    </>
  )
}
