import { CustomSelect, PrimaryButton, RegularButton, SlideUpDialog, SnackbarVariants } from '@wavetronix/common-components'
import CertificationExamsApi from '../../api/CertificationExamsApi'
import { DialogContent, Switch } from '@mui/material'
import { useEffect, useState } from 'react'
import { AddOutlined } from '@mui/icons-material'
import { useMsal } from '@azure/msal-react'
import { useSnackbar } from 'notistack'
import Question from '../Question'
import { v4 } from 'uuid'
import ImageTextField, { DEFAULT_IMAGEINFO } from '../components/ImageTextField'

export function ValidateTextHasCharacters(text) {
  return text === '' ? false : true
}

const IS_TRUE = element => element === true

export default function AddExamModal({ open, onClose, refetch }) {
  const [name, setNameState] = useState('')
  const [imageInfo, setImageInfo] = useState(DEFAULT_IMAGEINFO)
  const [questionType, setQuestionType] = useState('Multiple Choice')
  const [category, setCategory] = useState('Test')
  const [questionsMap, setQuestionsMap] = useState({})
  const [customPointValue, setCustomPointValue] = useState(false)
  const [examPointTotal, setExamPointTotal] = useState(0)

  const [isNameValid, setIsNameValid] = useState(false)
  const [areQuestionsValid, setAreQuestionsValid] = useState(false)
  const [isFormValid, setIsFormValid] = useState(true)

  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()

  const addExam = async exam => {
    await CertificationExamsApi.addExam(instance, accounts, exam).then(
      _ => {
        enqueueSnackbar('Add exam success', SnackbarVariants.SUCCESS)
        refetch()
        closeModal()
      },
      error => {
        enqueueSnackbar(`Failed to add exam`, SnackbarVariants.ERROR)
      }
    )
  }

  useEffect(() => {
    if (customPointValue) {
      let total = 0
      for (let question of Object.values(questionsMap)) {
        total += Number(question.pointValue)
      }
      setExamPointTotal(total)
    } else setExamPointTotal(Object.keys(questionsMap).length)
  }, [questionsMap, customPointValue])

  useEffect(() => {
    if (questionsMap) {
      setAreQuestionsValid(ValidateQuestionsMap(questionsMap))
    }
  }, [questionsMap])

  useEffect(() => {
    setIsNameValid(ValidateTextHasCharacters(name))
  }, [name])

  useEffect(() => {
    setIsFormValid(isNameValid && areQuestionsValid)
  }, [isNameValid, areQuestionsValid])

  function AddQuestion() {
    let guid = v4()
    let question = {
      number: Object.keys(questionsMap).length + 1,
      type: questionType,
      prompt: '',
      imageInfo: DEFAULT_IMAGEINFO,
      answerData: questionType === 'Freeform' ? '' : {},
      pointValue: 1
    }
    setQuestionsMap({ ...questionsMap, [guid]: question })

    enqueueSnackbar('Question added!', {
      variant: 'success',
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      autoHideDuration: 3000
    })
  }

  function CreateExam() {
    let questions = []

    for (let question of Object.values(questionsMap)) {
      questions.push({
        number: question.number,
        type: question.type,
        prompt: question.prompt,
        imageInfo: question.imageInfo,
        matches: question.type === 'Matching' ? Object.values(question.answerData) : [],
        multipleChoiceAnswers: question.type === 'Multiple Choice' ? Object.values(question.answerData) : [],
        freeResponseAnswer: question.type === 'Freeform' ? question.answerData : '',
        point: customPointValue && question.pointValue > 0 ? Number(question.pointValue) : 1
      })
    }

    let exam = {
      name: name,
      questions: questions,
      imageInfo: imageInfo,
      customPointValue: customPointValue,
      category: category,
      index: 0
    }

    addExam(exam)
  }

  function ValidateQuestionsMap(questionMap) {
    if (Object.keys(questionMap).length === 0) {
      return false
    }

    let truthArray = []
    let questions = Object.values(questionMap)

    for (let question of questions) {
      switch (question.type) {
        case 'Freeform':
          truthArray.push(ValidateFreeform(question))
          break
        case 'Matching':
          truthArray.push(ValidateMatching(question))
          break
        case 'Multiple Choice':
          truthArray.push(ValidateMultipleChoice(question))
          break
        default:
          return false
      }
    }

    return truthArray.every(IS_TRUE)

    function ValidateFreeform(question) {
      return ValidateTextHasCharacters(question.prompt) && ValidateTextHasCharacters(question.answerData)
    }

    function ValidateMatching(question) {
      let truthArray = []
      truthArray.push(ValidateTextHasCharacters(question.prompt))

      for (let match of Object.values(question.answerData)) {
        truthArray.push(ValidateTextHasCharacters(match.leftMatch))
        truthArray.push(ValidateTextHasCharacters(match.rightMatch))
      }

      return truthArray.every(IS_TRUE)
    }

    function ValidateMultipleChoice(question) {
      let truthArray = []
      truthArray.push(ValidateTextHasCharacters(question.prompt))

      let possibleAnswers = []

      for (let multipleChoiceAnswer of Object.values(question.answerData)) {
        truthArray.push(ValidateTextHasCharacters(multipleChoiceAnswer.answer))
        possibleAnswers.push(multipleChoiceAnswer.isCorrect)
      }

      return truthArray.every(IS_TRUE) && possibleAnswers.some(IS_TRUE)
    }
  }

  const DeleteQuestion = guid => {
    let questions = Object.values(questionsMap)
    for (let question of questions) {
      if (question.number > questionsMap[guid].number) {
        question.number -= 1
      }
    }
    delete questionsMap[guid]
    setQuestionsMap({ ...questionsMap })
  }

  const ReorderQuestions = (guid, newQuestionNumber) => {
    let oldQuestionNumber = questionsMap[guid].number
    let movingQuestionDown = newQuestionNumber < oldQuestionNumber

    let examLength = Object.keys(questionsMap).length
    if (newQuestionNumber > examLength) {
      newQuestionNumber = examLength
    }

    let questions = Object.values(questionsMap)
    for (let question of questions) {
      if (movingQuestionDown) {
        if (question.number >= newQuestionNumber && question.number <= oldQuestionNumber) {
          question.number += 1
        }
      } else {
        if (question.number <= newQuestionNumber && question.number > oldQuestionNumber) {
          question.number -= 1
        }
      }
    }
    questionsMap[guid].number = Number(newQuestionNumber)

    setQuestionsMap({ ...questionsMap })
  }

  function closeModal() {
    onClose()
  }

  return (
    <SlideUpDialog
      id='addExamModal'
      fullScreen
      open={open}
      onClose={closeModal}
      title='Create Exam'
      actions={
        <PrimaryButton id='addExamCreateButton' onClick={CreateExam} disabled={isFormValid === false}>
          Create
        </PrimaryButton>
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          border: '1px solid black',
          padding: 10,
          zIndex: 10,
          backgroundColor: 'white'
        }}
      >
        <div style={{ maxWidth: '80%', minWidth: '50%' }}>
          <ImageTextField
            id='addExamImageTextField'
            label='Exam Name'
            size='small'
            value={name ? name : ''}
            onChange={e => {
              setNameState(e.target.value)
              setIsNameValid(ValidateTextHasCharacters(e.target.value))
            }}
            imageInfo={imageInfo}
            setImageInfo={setImageInfo}
          />
          <CustomSelect
            id='addExamQuestionTypeSelect'
            label='Question Type'
            options={['Multiple Choice']} // add 'Matching' when ready
            style={{ width: '20%', marginLeft: 10 }}
            value={questionType}
            onChange={e => setQuestionType(e.target.value)}
          />
          <RegularButton
            id='addExamQuestionAddButton'
            onClick={AddQuestion}
            startIcon={<AddOutlined />}
            style={{ marginLeft: 10 }}
          >
            Add Question
          </RegularButton>
          <CustomSelect
            id='addExamCategorySelect'
            label='Category'
            options={['Test', 'Expanse', 'Legacy']}
            style={{ width: '20%', marginLeft: 10 }}
            value={category}
            onChange={e => setCategory(e.target.value)}
          />
        </div>
        <div style={{ display: 'inline', alignContent: 'center' }}>
          <div>Exam Point Total: {examPointTotal}</div>
          <div>
            <span>Custom Point Values</span>
            <Switch
              checked={customPointValue ? customPointValue : false}
              onClick={() => {
                setCustomPointValue(!customPointValue)
              }}
            ></Switch>
          </div>
        </div>
      </div>
      <DialogContent>
        {Object.keys(questionsMap)
          .sort((a, b) => {
            if (questionsMap[a].number < questionsMap[b].number) {
              return -1
            } else {
              return 1
            }
          })
          .map(guid => {
            return (
              <Question
                key={guid}
                question={questionsMap[guid]}
                setQuestionsMap={setQuestionsMap}
                questionsMap={questionsMap}
                customPointValue={customPointValue}
                guid={guid}
                deleteQuestion={guid => {
                  DeleteQuestion(guid)
                }}
                reorderQuestions={(guid, newQuestionNumber) => {
                  ReorderQuestions(guid, newQuestionNumber)
                }}
                protoMap={questionsMap[guid].answerData}
              ></Question>
            )
          })}
      </DialogContent>
    </SlideUpDialog>
  )
}
