import { Card, CardHeader, CardContent } from '@mui/material'
import { WtxColors } from '@wavetronix/common-components'

export default function RecipientList(props) {
  return (
    <Card sx={{ ...props.sx, width: '100%', border: 'solid 1px', borderColor: WtxColors.CONCRETE, boxShadow: 'none' }}>
      <CardHeader title={<div style={{ fontSize: '20px' }}>Recipient List</div>} sx={{ backgroundColor: WtxColors.CONCRETE }} />
      <CardContent sx={{ minHeight: 50, whiteSpace: 'pre' }}>{props.recipientList}</CardContent>
    </Card>
  )
}
