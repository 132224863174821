import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined'
import { DeleteButton, RegularButton, WtxColors } from '@wavetronix/common-components'
import { DeleteOutlined, Close } from '@mui/icons-material'
import { DialogContent, Grid, Box } from '@mui/material'
import { AddOutlined } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import CheckIcon from '@mui/icons-material/Check'
import { useState, useEffect } from 'react'
import { TextField } from '@mui/material'
import { Card } from '@mui/material'
// import { Fragment } from 'react'
import { v4 } from 'uuid'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ImageTextField from './components/ImageTextField'
export default function Question({
  customPointValue,
  question,
  setQuestionsMap,
  questionsMap,
  guid,
  deleteQuestion,
  reorderQuestions,
  protoMap
}) {
  const [answerMap, setAnswerMap] = useState(protoMap)
  const [matchingMap] = useState(protoMap)
  const [displayNumber, setDisplayNumber] = useState('')
  const [pointValue, setPointValue] = useState(
    (() => {
      return questionsMap[guid].pointValue
    })()
  )

  useEffect(() => {
    switch (question.type) {
      case 'Matching':
        setQuestionsMap(qm => ({ ...qm, [guid]: { ...qm[guid], answerData: matchingMap, pointValue: pointValue } }))
        break

      case 'Multiple Choice':
        setQuestionsMap(qm => ({ ...qm, [guid]: { ...qm[guid], answerData: answerMap, pointValue: pointValue } }))
        break
      default:
        setQuestionsMap(qm => ({ ...qm, [guid]: { ...qm[guid], pointValue: pointValue } }))
        break
    }
  }, [matchingMap, answerMap, pointValue, guid, question.type, setQuestionsMap])

  let cardMargin = 20
  let buttonMargin = 10
  let textFieldMargin = 10
  let textFieldSize = 'small'

  const deleteAnswer = qguid => {
    delete answerMap[qguid]
    setAnswerMap({ ...answerMap })
  }

  // function AddMatch() {
  //   let matchGuid = v4()
  //   setMatchingMap({ ...matchingMap, [matchGuid]: { leftMatch: '', rightMatch: '' } })
  // }

  function AddAnswer() {
    let guid = v4()
    setAnswerMap({ ...answerMap, [guid]: { answer: '', isCorrect: false, imageInfo: { position: 'Bottom', image: '' } } })
  }

  switch (question.type) {
    case 'Freeform':
      return (
        <Grid container>
          <Grid item md={3} />
          <Grid item md={6}>
            <Card variant='outlined' style={{ marginBottom: cardMargin, border: '1px solid' }}>
              <DialogContent>
                <div style={{ marginBottom: 10, display: 'flex', width: '100%' }}>
                  <div style={{ marginTop: 10 }}>Question #{question.number}</div>
                  <DeleteButton
                    id={`${guid}QuestionDeleteButton`}
                    startIcon={<DeleteOutlined />}
                    style={{ marginLeft: buttonMargin }}
                    onClick={async () => {
                      await deleteQuestion(guid)
                    }}
                  >
                    Delete
                  </DeleteButton>

                  {customPointValue ? (
                    <TextField
                      id={`${guid}QuestionPointsTextField`}
                      size={textFieldSize}
                      style={{ width: '15%', marginLeft: 'auto' }}
                      label='Point Value'
                      value={pointValue ? pointValue : ''}
                      onChange={e => {
                        if (e.target.value >= 0) {
                          setPointValue(e.target.value)
                        }
                      }}
                    />
                  ) : (
                    <></>
                  )}

                  <TextField
                    id={`${guid}QuestionNewOrderTextfield`}
                    inputProps={{ maxLength: 3 }}
                    label='New #'
                    size={textFieldSize}
                    style={{ width: '10%', marginLeft: 'auto' }}
                    value={displayNumber ? displayNumber : ''}
                    onChange={e => {
                      if (e.target.value >= 0) {
                        setDisplayNumber(e.target.value)
                      }
                    }}
                  />
                  <RegularButton
                    id={`${guid}QuestionReorderButton`}
                    startIcon={<ImportExportOutlinedIcon />}
                    style={{ marginLeft: buttonMargin, width: '14%' }}
                    onClick={() => {
                      if (displayNumber) {
                        reorderQuestions(guid, displayNumber)
                        setDisplayNumber()
                      }
                    }}
                  >
                    Reorder
                  </RegularButton>
                </div>
                <ImageTextField
                  id={`${guid}QuestionImageTextField`}
                  multiline
                  label='Prompt'
                  style={{ width: '100%', marginTop: textFieldMargin }}
                  size={textFieldSize}
                  value={question.prompt ? question.prompt : ''}
                  onChange={e => {
                    setQuestionsMap(qm => ({ ...qm, [guid]: { ...qm[guid], prompt: e.target.value } }))
                  }}
                  imageInfo={question.imageInfo}
                  setImageInfo={info => setQuestionsMap(qm => ({ ...qm, [guid]: { ...qm[guid], imageInfo: info } }))}
                />
                <TextField
                  id={`${guid}QuestionAnswerTextField`}
                  multiline
                  label='Answer'
                  style={{ width: '100%', marginTop: textFieldMargin }}
                  size={textFieldSize}
                  value={question.answerData ? question.answerData : ''}
                  onChange={e => {
                    setQuestionsMap(qm => ({ ...qm, [guid]: { ...qm[guid], answerData: e.target.value } }))
                  }}
                />
              </DialogContent>
            </Card>
          </Grid>
          <Grid item md={3} />
        </Grid>
      )
    case 'Matching':
      return (
        <Grid container>
          <Grid item md={3} />
          <Grid item md={6}>
            <Card variant='outlined' style={{ marginBottom: cardMargin, border: '1px solid' }}>
              <DialogContent>
                <div style={{ marginBottom: 10, display: 'flex', width: '100%' }}>
                  <div style={{ marginTop: 10 }}>Question #{question.number}</div>
                  {/* <RegularButton
                    startIcon={<AddOutlined />}
                    style={{ marginLeft: buttonMargin }}
                    onClick={() => {
                      AddMatch()
                    }}>
                    Add Match
                  </RegularButton> */}
                  <DeleteButton
                    id={`${guid}QuestionDeleteButton`}
                    startIcon={<DeleteOutlined />}
                    style={{ marginLeft: buttonMargin }}
                    onClick={async () => {
                      await deleteQuestion(guid)
                    }}
                  >
                    Delete
                  </DeleteButton>

                  {customPointValue ? (
                    <TextField
                      id={`${guid}QuestionPointsTextField`}
                      size={textFieldSize}
                      style={{ width: '15%', marginLeft: 'auto' }}
                      label='Point Value'
                      value={pointValue ? pointValue : ''}
                      onChange={e => {
                        if (e.target.value >= 0) {
                          setPointValue(e.target.value)
                        }
                      }}
                    />
                  ) : (
                    <></>
                  )}

                  <TextField
                    id={`${guid}QuestionNewOrderTextfield`}
                    inputProps={{ maxLength: 3 }}
                    label='New #'
                    size={textFieldSize}
                    style={{ width: '10%', marginLeft: 'auto' }}
                    value={displayNumber ? displayNumber : ''}
                    onChange={e => {
                      if (e.target.value >= 0) {
                        setDisplayNumber(e.target.value)
                      }
                    }}
                  />
                  <RegularButton
                    id={`${guid}QuestionReorderButton`}
                    startIcon={<ImportExportOutlinedIcon />}
                    style={{ marginLeft: buttonMargin, width: '14%' }}
                    onClick={() => {
                      if (displayNumber) {
                        reorderQuestions(guid, displayNumber)
                        setDisplayNumber()
                      }
                    }}
                  >
                    Reorder
                  </RegularButton>
                </div>
                <h3>Matching questions are under construction</h3>
                {/* <ImageTextField
                  multiline
                  label='Prompt'
                  style={{ width: '100%', marginTop: textFieldMargin }}
                  size={textFieldSize}
                  value={question.prompt ? question.prompt : ''}
                  onChange={e => {
                    setQuestionsMap(qm => ({ ...qm, [guid]: { ...qm[guid], prompt: e.target.value } }))
                  }}
                  imageInfo={question.imageInfo}
                  setImageInfo={info => setQuestionsMap(qm => ({ ...qm, [guid]: { ...qm[guid], imageInfo: info } }))}
                />
                <DialogContent style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                  {Object.keys(matchingMap).map((qguid, i) => {
                    return (
                      <Fragment key={i}>
                        <ImageTextField
                          multiline
                          label='1st Half'
                          style={{ width: '50%', marginBottom: 10 }}
                          size={textFieldSize}
                          value={matchingMap[qguid].leftMatch}
                          onChange={e => {
                            setMatchingMap({ ...matchingMap, [qguid]: { ...matchingMap[qguid], leftMatch: e.target.value } })
                          }}
                          imageInfo={matchingMap[qguid].leftImageInfo}
                          setImageInfo={info =>
                            setMatchingMap({ ...matchingMap, [qguid]: { ...matchingMap[qguid], leftImageInfo: info } })
                          }
                        />
                        <ImageTextField
                          multiline
                          label='2nd Half'
                          style={{ width: '50%', marginBottom: 10 }}
                          size={textFieldSize}
                          value={matchingMap[qguid].rightMatch}
                          onChange={e => {
                            setMatchingMap({ ...matchingMap, [qguid]: { ...matchingMap[qguid], rightMatch: e.target.value } })
                          }}
                          imageInfo={matchingMap[qguid].rightImageInfo}
                          setImageInfo={info =>
                            setMatchingMap({ ...matchingMap, [qguid]: { ...matchingMap[qguid], rightImageInfo: info } })
                          }
                        />
                      </Fragment>
                    )
                  })}
                </DialogContent> */}
              </DialogContent>
            </Card>
          </Grid>
          <Grid item md={3} />
        </Grid>
      )
    case 'Multiple Choice':
      return (
        <Grid container>
          <Grid item md={3} />
          <Grid item md={6}>
            <Card variant='outlined' style={{ marginBottom: cardMargin, border: '1px solid' }}>
              <DialogContent>
                <div style={{ marginBottom: 10, display: 'flex', width: '100%' }}>
                  <div style={{ marginTop: 10 }}>Question #{question.number}</div>
                  <RegularButton
                    id={`${guid}QuestionAddAnswerButton`}
                    startIcon={<AddOutlined />}
                    style={{ marginLeft: buttonMargin }}
                    onClick={() => {
                      AddAnswer()
                    }}
                  >
                    Add Answer
                  </RegularButton>
                  <DeleteButton
                    id={`${guid}QuestionDeleteButton`}
                    startIcon={<DeleteOutlined />}
                    style={{ marginLeft: buttonMargin }}
                    onClick={async () => {
                      await deleteQuestion(guid)
                    }}
                  >
                    Delete
                  </DeleteButton>

                  {customPointValue ? (
                    <TextField
                      id={`${guid}QuestionPointsTextField`}
                      size={textFieldSize}
                      style={{ width: '15%', marginLeft: 'auto' }}
                      label='Point Value'
                      value={pointValue ? pointValue : ''}
                      onChange={e => {
                        if (e.target.value >= 0) {
                          setPointValue(e.target.value)
                        }
                      }}
                    />
                  ) : (
                    <></>
                  )}

                  <TextField
                    id={`${guid}QuestionNewOrderTextfield`}
                    inputProps={{ maxLength: 3 }}
                    label='New #'
                    size={textFieldSize}
                    style={{ width: '10%', marginLeft: 'auto' }}
                    value={displayNumber ? displayNumber : ''}
                    onChange={e => {
                      if (e.target.value >= 0) {
                        setDisplayNumber(e.target.value)
                      }
                    }}
                  />
                  <RegularButton
                    id={`${guid}QuestionReorderButton`}
                    startIcon={<ImportExportOutlinedIcon />}
                    style={{ marginLeft: buttonMargin, width: '14%' }}
                    onClick={() => {
                      if (displayNumber) {
                        reorderQuestions(guid, displayNumber)
                        setDisplayNumber()
                      }
                    }}
                  >
                    Reorder
                  </RegularButton>
                </div>
                <ImageTextField
                  id={`${guid}QuestionImageTextField`}
                  multiline
                  label='Prompt'
                  style={{ width: '100%', marginTop: textFieldMargin }}
                  size={textFieldSize}
                  value={question.prompt ? question.prompt : ''}
                  onChange={e => {
                    setQuestionsMap(qm => ({ ...qm, [guid]: { ...qm[guid], prompt: e.target.value } }))
                  }}
                  imageInfo={question.imageInfo}
                  setImageInfo={info => setQuestionsMap(qm => ({ ...qm, [guid]: { ...qm[guid], imageInfo: info } }))}
                />
                <DialogContent style={{ paddingRight: '0px' }}>
                  {Object.keys(answerMap).map((qguid, i) => {
                    return (
                      <Box key={i} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '15px',
                            width: '100%'
                          }}
                        >
                          <IconButton
                            id={`${guid}${qguid}MultipleChoiceSetCorrectStateButton`}
                            sx={{ marginRight: '15px' }}
                            onClick={() => {
                              setAnswerMap({
                                ...answerMap,
                                [qguid]: { ...answerMap[qguid], isCorrect: !answerMap[qguid].isCorrect }
                              })
                            }}
                          >
                            {answerMap[qguid].isCorrect ? (
                              <CheckCircleIcon sx={{ color: WtxColors.GROWTH_GREEN }} />
                            ) : (
                              <CheckIcon />
                            )}
                          </IconButton>
                          <Box sx={{ flex: '1 1 auto' }} />
                          <ImageTextField
                            id={`${guid}${qguid}MultipleChoiceImageTextField`}
                            multiline
                            label='Answer'
                            style={{ width: '100%' }}
                            size={textFieldSize}
                            value={answerMap[qguid].answer}
                            onChange={e => {
                              setAnswerMap({ ...answerMap, [qguid]: { ...answerMap[qguid], answer: e.target.value } })
                            }}
                            imageInfo={answerMap[qguid].imageInfo}
                            setImageInfo={info =>
                              setAnswerMap({ ...answerMap, [qguid]: { ...answerMap[qguid], imageInfo: info } })
                            }
                          />
                          <IconButton
                            id={`${guid}${qguid}MultipleChoiceDeleteButton`}
                            sx={{ marginLeft: '5px' }}
                            onClick={() => deleteAnswer(qguid)}
                          >
                            <Close></Close>
                          </IconButton>
                        </div>
                      </Box>
                    )
                  })}
                </DialogContent>
              </DialogContent>
            </Card>
          </Grid>
          <Grid item md={3} />
        </Grid>
      )
    default:
      return 'ERROR - Question is of invalid type!'
  }
}
