import { TextField } from '@mui/material'

export const DEFAULT_USERS_FILTER = {
  firstName: '',
  lastName: '',
  email: ''
}

const nullCheck = s => (s ? s : '')

export const filterUsers = (filter, users) => {
  return users.filter(
    u =>
      nullCheck(u.givenName).toLowerCase().includes(filter.firstName.toLowerCase()) &&
      nullCheck(u.surname).toLowerCase().includes(filter.lastName.toLowerCase()) &&
      nullCheck(u.email).toLowerCase().includes(filter.email.toLowerCase())
  )
}

export default function UsersFilterDrawer({ filter, setFilter }) {
  const handleChange = (id, value) => setFilter(f => ({ ...f, [id]: value }))

  return (
    <>
      <TextField
        id='FirstNameFilterTextField'
        label='First Name'
        onChange={e => handleChange('firstName', e.target.value)}
        value={filter.firstName}
        variant='outlined'
        size='small'
        style={{ width: '100%' }}
      />
      <TextField
        id='LastNameFilterTextField'
        label='Last Name'
        style={{ marginTop: '10px', width: '100%' }}
        onChange={e => handleChange('lastName', e.target.value)}
        value={filter.lastName}
        variant='outlined'
        size='small'
      />
      <TextField
        id='EmailFilterTextField'
        label='Email'
        style={{ marginTop: '10px', width: '100%' }}
        onChange={e => handleChange('email', e.target.value)}
        value={filter.email}
        variant='outlined'
        size='small'
      />
    </>
  )
}
