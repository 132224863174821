import {
  CustomDataGrid,
  PersistantFilterDiv,
  ErrorMessage,
  CenteredDiv,
  RegularButton,
  HasAccess
} from '@wavetronix/common-components'
import ExamFilterDrawer, { DEFAULT_EXAM_FILTER, filterExams } from '../drawers/ExamFilterDrawer'
import CertificationExamsApi from '../../api/CertificationExamsApi'
import AddExamModal from '../modals/AddExamModal'
import EditExamModal from '../modals/EditExamModal'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { env } from '../../index'

export default function ExamsPage(props) {
  const [modalState, setModalState] = useState(false)
  const [editModalState, setEditModalState] = useState(false)
  const [selectedExam, setSelectedExam] = useState(null)

  const [filter, setFilter] = useState(DEFAULT_EXAM_FILTER)

  const { instance, accounts } = useMsal()

  const { data, isLoading, error, refetch } = useQuery(
    'exams',
    async () => await CertificationExamsApi.getExams(instance, accounts)
  )

  const columns = [
    {
      field: 'name',
      headerName: 'Exam Name',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      disableColumnMenu: true
    }
    // TODO - More columns?
  ]

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }
  return (
    <PersistantFilterDiv
      resetFilter={() => setFilter(DEFAULT_EXAM_FILTER)}
      drawer={<ExamFilterDrawer setFilter={setFilter} filter={filter} />}
      page={
        <>
          <AddExamModal open={modalState} onClose={() => setModalState(false)} refetch={refetch} />
          <EditExamModal
            open={editModalState}
            onClose={() => setEditModalState(false)}
            originalExam={selectedExam}
            refetch={refetch}
          />
          <div style={{ margin: '24px' }}>
            <HasAccess env={env} allowedRoles={['Exam Manager']}>
              <RegularButton id='addExamButton' onClick={() => setModalState(true)} style={{ marginBottom: 10 }}>
                Add Exam
              </RegularButton>
            </HasAccess>
            <CustomDataGrid
              autoHeight
              columns={columns}
              rows={data ? filterExams(filter, data) : []}
              isLoading={isLoading}
              onRowClick={rowInfo => {
                setSelectedExam(rowInfo.row)
                setEditModalState(true)
              }}
            />
          </div>
        </>
      }
    />
  )
}
