import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { MenuAppBar, NotFoundPage } from '@wavetronix/common-components'
import { CssBaseline } from '@mui/material'
import ExamsPage from './pages/ExamsPage'
import UsersPage from './pages/UsersPage'
import React, { useState } from 'react'
import PageNavbar from './PageNavbar'
import { env } from '../index.js'
import version from '../env/version.json'
import NexusAssetsPage from './NexusAssets/NexusAssetsPage'

const classes = {
  paper: {
    width: 300
  },
  dataGrid: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  },
  filterTitle: {
    fontFamily: 'Klavika',
    fontSize: 22
  },
  title: {
    fontFamily: 'Klavika',
    fontSize: 26
  },
  inlineTitle: {
    fontFamily: 'Klavika',
    fontSize: '26px',
    display: 'inline'
  }
}

function MainPage() {
  const [state, setState] = useState({
    current: window.location.pathname.substring(1)
  })

  const URL_MAP = {
    exams: 0,
    users: 1,
    assets: 2,
    '': 0
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <CssBaseline />
      <MenuAppBar env={env} appHeader='Exam Management' />
      <BrowserRouter>
        <div className='content' style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <Switch>
              <Route path='/' exact>
                <Redirect to='/exams' />
              </Route>
              <Route
                path='/exams'
                render={() => (
                  <div className='content' style={{ display: 'flex' }}>
                    <PageNavbar value={URL_MAP[state.current]} onChangeCallback={id => setState(s => ({ ...s, current: id }))} />
                    <div style={{ width: '100%' }}>
                      <ExamsPage styles={classes} />
                    </div>
                  </div>
                )}
                exact
              />
              <Route
                path='/users'
                render={() => (
                  <div className='content' style={{ display: 'flex' }}>
                    <PageNavbar value={URL_MAP[state.current]} onChangeCallback={id => setState(s => ({ ...s, current: id }))} />
                    <div style={{ width: '100%' }}>
                      <UsersPage styles={classes} />
                    </div>
                  </div>
                )}
                exact
              />
              <Route path='/version' render={() => <div>{version.version}</div>} exact />
              <Route
                path='/assets'
                render={() => (
                  <div className='content' style={{ display: 'flex' }}>
                    <PageNavbar value={URL_MAP[state.current]} onChangeCallback={id => setState(s => ({ ...s, current: id }))} />
                    <div style={{ width: '100%' }}>
                      <NexusAssetsPage />
                    </div>
                  </div>
                )}
                exact
              />
              {/* This wildcard route needs to stay at the bottom */}
              <Route path='*' component={NotFoundPage} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    </LocalizationProvider>
  )
}

export default MainPage
