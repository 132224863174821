import { TextField } from '@mui/material'
import { CustomAccordion, ListSelector } from '@wavetronix/common-components'

export const DEFAULT_EXAM_FILTER = {
  name: '',
  category: []
}

const nullCheck = s => (s ? s : '')

export const filterExams = (filter, exams) =>
  exams.filter(e => {
    return (
      nullCheck(e.name).toLowerCase().includes(filter.name.toLowerCase()) &&
      (filter.category.length > 0 ? filter.category.map(c => c.value).includes(nullCheck(e.category)) : true)
    )
  })

export default function ExamFilterDrawer({ filter, setFilter }) {
  return (
    <>
      <TextField
        id='ExamNameFilterTextField'
        label='Name'
        size='small'
        variant='outlined'
        style={{ width: '100%' }}
        onChange={e => setFilter(f => ({ ...f, name: e.target.value }))}
        value={filter.name ? filter.name : ''}
      />
      <CustomAccordion
        id='ExamCategoryFilterSelect'
        title='Category'
        style={{
          marginTop: '15px',
          borderRadius: '5px',
          width: '100%',
          boxShadow: 'none',
          border: '1px solid rgb(196, 196, 196)',
          '&:before': {
            display: 'none'
          }
        }}
        titleStyle={{ marginRight: '-9px' }}
        detailStyle={{ padding: '0px', margin: '0px', marginTop: '0px' }}
        label='Category'
        children={
          <ListSelector
            selectedOptions={filter.category}
            options={[
              { id: 'Test', value: 'Test' },
              { id: 'Expanse', value: 'Expanse' },
              { id: 'Legacy', value: 'Legacy' }
            ]}
            onChange={e => setFilter(f => ({ ...f, category: [...e] }))}
          />
        }
      />
    </>
  )
}
