import { DialogContent } from '@mui/material'
import { SlideUpDialog, DeleteButton } from '@wavetronix/common-components'

export default function DeleteConfirmationModal({ message, open, onClose, onDelete }) {
  return (
    <SlideUpDialog
      id='deleteConfirmationModal'
      fullScreen={false}
      maxWidth={'sm'}
      open={open}
      onClose={onClose}
      title={'Delete Confirmation'}
      actions={
        <DeleteButton id='deleteButton' onClick={onDelete}>
          Delete
        </DeleteButton>
      }
    >
      <DialogContent>{message}</DialogContent>
    </SlideUpDialog>
  )
}
