import {
  CustomSelect,
  PrimaryButton,
  RegularButton,
  SlideUpDialog,
  SnackbarVariants,
  DeleteButton,
  HasAccess,
  useHasAccess
} from '@wavetronix/common-components'
import CertificationExamsApi from '../../api/CertificationExamsApi'
import { DialogContent, Box, Switch } from '@mui/material'
import { useEffect, useState } from 'react'
import { AddOutlined } from '@mui/icons-material'
import { useMsal } from '@azure/msal-react'
import { useSnackbar } from 'notistack'
import Question from '../Question'
import { v4 } from 'uuid'
import ImageTextField, { DEFAULT_IMAGEINFO } from '../components/ImageTextField'
import PreviewExamView from '../components/PreviewExamView'
import { env } from '../../index'

export function ValidateTextHasCharacters(text) {
  return text === '' ? false : true
}

const IS_TRUE = element => element === true

export default function EditExamModal({ open, onClose, originalExam, refetch }) {
  const [name, setNameState] = useState('')
  const [imageInfo, setImageInfo] = useState(DEFAULT_IMAGEINFO)
  const [questionType, setQuestionType] = useState('Multiple Choice')
  const [category, setCategory] = useState(originalExam ? originalExam.category : 'Test')
  const [questionsMap, setQuestionsMap] = useState({})
  const [isNameValid, setIsNameValid] = useState(false)
  const [areQuestionsValid, setAreQuestionsValid] = useState(false)
  const [isFormValid, setIsFormValid] = useState(true)
  const [viewPreview, setViewPreview] = useState(!useHasAccess(['Exam Manager'], env))
  const [customPointValue, setCustomPointValue] = useState(originalExam ? originalExam.isCustomPointValue : false)
  const [examPointTotal, setExamPointTotal] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)

  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (originalExam && open) {
      setNameState(originalExam.name)
      setImageInfo(originalExam.imageInfo)
      setCustomPointValue(originalExam.isCustomPointValue)
      setCategory(originalExam.category)

      let tempMap = {}
      for (let question of originalExam.questions) {
        let guid = v4()
        tempMap[guid] = {
          number: question.number,
          type: question.type,
          prompt: question.prompt,
          imageInfo: question.imageInfo,
          pointValue: question.point > 0 ? question.point : 1
        }
        switch (question.type) {
          case 'Freeform':
            tempMap[guid] = { ...tempMap[guid], answerData: question.freeResponseAnswer }
            break
          case 'Matching':
            for (let match of question.matches) {
              let qguid = v4()
              tempMap[guid] = { ...tempMap[guid], answerData: { ...tempMap[guid].answerData, [qguid]: match } }
            }
            break
          case 'Multiple Choice':
            for (let answer of question.multipleChoiceAnswers) {
              let qguid = v4()
              tempMap[guid] = { ...tempMap[guid], answerData: { ...tempMap[guid].answerData, [qguid]: answer } }
            }
            break
          default:
            break
        }
      }
      setQuestionsMap(tempMap)
    } else setQuestionsMap({})
  }, [originalExam, open])

  useEffect(() => {
    if (customPointValue) {
      let total = 0
      for (let question of Object.values(questionsMap)) {
        total += Number(question.pointValue)
      }
      setExamPointTotal(total)
    } else setExamPointTotal(Object.keys(questionsMap).length)
  }, [questionsMap, customPointValue])

  useEffect(() => {
    if (questionsMap) {
      setAreQuestionsValid(ValidateQuestionsMap(questionsMap))
    }
  }, [questionsMap])

  useEffect(() => {
    setIsNameValid(ValidateTextHasCharacters(name))
  }, [name])

  useEffect(() => {
    setIsFormValid(isNameValid && areQuestionsValid && examPointTotal > 0)
  }, [isNameValid, areQuestionsValid, examPointTotal])

  function AddQuestion() {
    let guid = v4()
    let question = {
      number: Object.keys(questionsMap).length + 1,
      type: questionType,
      prompt: '',
      answerData: questionType === 'Freeform' ? '' : {},
      pointValue: 1
    }
    setQuestionsMap({ ...questionsMap, [guid]: question })

    enqueueSnackbar('Question added!', {
      variant: 'success',
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      autoHideDuration: 3000
    })
  }

  const UpdateExam = async () => {
    let questions = []

    for (let question of Object.values(questionsMap)) {
      questions.push({
        number: question.number,
        imageInfo: question.imageInfo,
        type: question.type,
        prompt: question.prompt,
        matches: question.type === 'Matching' ? Object.values(question.answerData) : [],
        multipleChoiceAnswers: question.type === 'Multiple Choice' ? Object.values(question.answerData) : [],
        freeResponseAnswer: question.type === 'Freeform' ? question.answerData : '',
        point: customPointValue && question.pointValue > 0 ? Number(question.pointValue) : 1
      })
    }

    let exam = {
      ...originalExam,
      name: name,
      imageInfo: imageInfo,
      questions: questions,
      isCustomPointValue: customPointValue,
      category: category
    }

    await CertificationExamsApi.updateExam(instance, accounts, exam).then(
      _ => {
        enqueueSnackbar('Exam successfully edited', SnackbarVariants.SUCCESS)
        refetch()
        closeModal()
      },
      error => {
        enqueueSnackbar(`Failed to add exam`, SnackbarVariants.ERROR)
      }
    )
  }

  const DeleteExam = async () => {
    setDeleteModal(false)
    await CertificationExamsApi.deleteExam(instance, accounts, originalExam.id).then(
      _ => {
        enqueueSnackbar('Delete exam success', SnackbarVariants.SUCCESS)
        refetch()
        closeModal()
      },
      error => {
        enqueueSnackbar(`Failed to delete exam`, SnackbarVariants.ERROR)
      }
    )
  }

  const PreviewExam = async () => {
    setViewPreview(prev => prev === false)
  }

  function ValidateQuestionsMap(questionMap) {
    if (Object.keys(questionMap).length === 0) {
      return false
    }

    let truthArray = []
    let questions = Object.values(questionMap)

    for (let question of questions) {
      switch (question.type) {
        case 'Freeform':
          truthArray.push(ValidateFreeform(question))
          break
        case 'Matching':
          truthArray.push(ValidateMatching(question))
          break
        case 'Multiple Choice':
          truthArray.push(ValidateMultipleChoice(question))
          break
        default:
          return false
      }
    }

    return truthArray.every(IS_TRUE)

    function ValidateFreeform(question) {
      return ValidateTextHasCharacters(question.prompt) && ValidateTextHasCharacters(question.answerData)
    }

    function ValidateMatching(question) {
      let truthArray = []
      truthArray.push(ValidateTextHasCharacters(question.prompt))
      for (let match of Object.values(question.answerData)) {
        truthArray.push(ValidateTextHasCharacters(match.leftMatch))
        truthArray.push(ValidateTextHasCharacters(match.rightMatch))
      }

      return truthArray.every(IS_TRUE)
    }

    function ValidateMultipleChoice(question) {
      let truthArray = []
      truthArray.push(ValidateTextHasCharacters(question.prompt))

      let possibleAnswers = []

      for (let multipleChoiceAnswer of Object.values(question.answerData)) {
        truthArray.push(ValidateTextHasCharacters(multipleChoiceAnswer.answer))
        possibleAnswers.push(multipleChoiceAnswer.isCorrect)
      }

      return truthArray.every(IS_TRUE) && possibleAnswers.some(IS_TRUE)
    }
  }

  const DeleteQuestion = guid => {
    let questions = Object.values(questionsMap)
    for (let question of questions) {
      if (question.number > questionsMap[guid].number) {
        question.number -= 1
      }
    }
    delete questionsMap[guid]
    setQuestionsMap({ ...questionsMap })
  }

  const ReorderQuestions = (guid, newQuestionNumber) => {
    let oldQuestionNumber = questionsMap[guid].number
    let movingQuestionDown = newQuestionNumber < oldQuestionNumber

    let examLength = Object.keys(questionsMap).length
    if (newQuestionNumber > examLength) {
      newQuestionNumber = examLength
    }

    let questions = Object.values(questionsMap)
    for (let question of questions) {
      if (movingQuestionDown) {
        if (question.number >= newQuestionNumber && question.number <= oldQuestionNumber) {
          question.number += 1
        }
      } else {
        if (question.number <= newQuestionNumber && question.number > oldQuestionNumber) {
          question.number -= 1
        }
      }
    }
    questionsMap[guid].number = Number(newQuestionNumber)

    setQuestionsMap({ ...questionsMap })
  }

  function closeModal() {
    onClose()
  }

  return (
    <SlideUpDialog
      id='editExamModal'
      fullScreen
      open={open}
      onClose={closeModal}
      title={viewPreview ? 'Preview Exam' : 'Edit Exam'}
      actions={
        <HasAccess
          env={env}
          allowedRoles={['Exam Manager']}
          unauthorizedControl={<RegularButton onClick={closeModal}>Close</RegularButton>}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <DeleteButton
              onClick={() => {
                setDeleteModal(true)
              }}
            >
              Delete
            </DeleteButton>
            <Box sx={{ flex: '1 1 auto' }} />
            <RegularButton id='editPreviewExamButton' onClick={PreviewExam} style={{ marginRight: '15px' }}>
              {viewPreview === false ? 'Preview Exam' : 'Edit Exam'}
            </RegularButton>

            <PrimaryButton id='editExamUpdateButton' onClick={UpdateExam} disabled={isFormValid === false}>
              Update
            </PrimaryButton>
          </Box>
        </HasAccess>
      }
    >
      {viewPreview === true ? (
        <PreviewExamView questionsMap={questionsMap} examName={name} examImageInfo={imageInfo} showPoints={customPointValue} />
      ) : (
        <HasAccess env={env} allowedRoles={['Exam Manager']}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'sticky',
              top: 0,
              border: '1px solid black',
              padding: 10,
              zIndex: 10,
              backgroundColor: 'white'
            }}
          >
            <div style={{ maxWidth: '80%', minWidth: '50%' }}>
              <ImageTextField
                id='editExamImageTextField'
                label='Exam Name'
                size='small'
                value={name ? name : ''}
                onChange={e => {
                  setNameState(e.target.value)
                }}
                imageInfo={imageInfo}
                setImageInfo={setImageInfo}
              />
              <CustomSelect
                id='editExamQuestionTypeSelect'
                label='Question Type'
                options={['Multiple Choice']} // add 'Matching' when ready
                style={{ width: '20%', marginLeft: 10 }}
                value={questionType}
                onChange={e => setQuestionType(e.target.value)}
              />
              <RegularButton
                id='editExamQuestionAddButton'
                onClick={AddQuestion}
                startIcon={<AddOutlined />}
                style={{ marginLeft: 10 }}
              >
                Add Question
              </RegularButton>
              <CustomSelect
                id='addExamCategorySelect'
                label='Category'
                options={['Test', 'Expanse', 'Legacy']}
                style={{ width: '20%', marginLeft: 10 }}
                value={category}
                onChange={e => setCategory(e.target.value)}
              />
            </div>
            <div style={{ display: 'inline', alignContent: 'center', minWidth: '20%' }}>
              <div>Exam Point Total: {examPointTotal}</div>
              <div>
                <span>Custom Point Values</span>
                <Switch
                  checked={customPointValue ? customPointValue : false}
                  onClick={() => {
                    setCustomPointValue(!customPointValue)
                  }}
                ></Switch>
              </div>
            </div>
          </div>
          <DialogContent>
            <SlideUpDialog
              id='deleteModal'
              open={deleteModal}
              title='Delete Exam?'
              actions={
                <HasAccess
                  env={env}
                  allowedRoles={['Exam Manager']}
                  unauthorizedControl={
                    <RegularButton
                      onClick={() => {
                        setDeleteModal(false)
                      }}
                    >
                      Close
                    </RegularButton>
                  }
                >
                  <DeleteButton onClick={DeleteExam}>Yes, I'm sure.</DeleteButton>
                  <PrimaryButton
                    onClick={() => {
                      setDeleteModal(false)
                    }}
                  >
                    No, don't delete.
                  </PrimaryButton>
                </HasAccess>
              }
              onClose={() => {
                setDeleteModal(false)
              }}
            >
              <DialogContent>
                <h3>Are you sure you want to delete {originalExam ? originalExam.name : 'this exam'}?</h3>
                <h3>This action cannot be undone.</h3>
              </DialogContent>
            </SlideUpDialog>
            {Object.keys(questionsMap)
              .sort((a, b) => {
                if (questionsMap[a].number < questionsMap[b].number) {
                  return -1
                } else {
                  return 1
                }
              })
              .map(guid => {
                return (
                  <Question
                    customPointValue={customPointValue}
                    key={guid}
                    question={questionsMap[guid]}
                    setQuestionsMap={setQuestionsMap}
                    questionsMap={questionsMap}
                    guid={guid}
                    deleteQuestion={guid => {
                      DeleteQuestion(guid)
                    }}
                    reorderQuestions={(guid, newQuestionNumber) => {
                      ReorderQuestions(guid, newQuestionNumber)
                    }}
                    protoMap={questionsMap[guid].answerData}
                  ></Question>
                )
              })}
          </DialogContent>
        </HasAccess>
      )}
    </SlideUpDialog>
  )
}
