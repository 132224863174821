import { CustomDataGrid, PersistantFilterDiv, WtxColors } from '@wavetronix/common-components'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import UsersApi from '../../api/UsersApi'
import UserModal from '../modals/UserModal'
import IssueExamModal from '../modals/IssueExamModal'
import UsersFilterDrawer, { DEFAULT_USERS_FILTER, filterUsers } from '../drawers/UsersFilterDrawer'
import { Fab, Tooltip, tooltipClasses } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import RecipientList from '../RecipientList'

const tooltipStyle = {
  popper: {
    sx: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
        marginTop: '0px'
      }
    },
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 18]
        }
      }
    ]
  }
}

export default function UsersPage(props) {
  const [selectedRows, setSelectedRows] = useState([])
  const [filter, setFilter] = useState(DEFAULT_USERS_FILTER)
  const [rows, setRows] = useState([])
  const [recipientList, setRecipientList] = useState([])

  const [userModalState, setUserModalState] = useState({
    open: false,
    user: {}
  })
  const [issueExamModalState, setIssueExamModalState] = useState({
    open: false,
    recipientList: recipientList
  })

  const { instance, accounts } = useMsal()

  const { data, isLoading } = useQuery('users', async () => await UsersApi.getUsers(instance, accounts))

  const columns = [
    {
      field: 'givenName',
      headerName: 'First Name',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'surname',
      headerName: 'Last Name',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      disableColumnMenu: true
    }
  ]

  useEffect(() => {
    if (data) {
      setRows(filterUsers(filter, data))
    }
  }, [data, filter])

  return (
    <PersistantFilterDiv
      page={
        <>
          <UserModal
            open={userModalState.open}
            onClose={() => setUserModalState({ open: false, user: {} })}
            user={userModalState.user}
            styles={props.styles}
          />
          <IssueExamModal
            open={issueExamModalState.open}
            onClose={() => setIssueExamModalState({ open: false, user: {} })}
            recipientList={issueExamModalState.recipientList}
            styles={props.styles}
          />
          <div style={{ margin: '24px', height: '100%' }}>
            <div style={{ width: '25%', height: '100%', marginLeft: 24, float: 'right' }}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  position: 'sticky',
                  top: '100px',
                  gap: '10px'
                }}
              >
                <div style={{ whiteSpace: 'nowrap' }}>
                  <Tooltip slotProps={tooltipStyle} title='Add selected users to recipients'>
                    <span>
                      <Fab
                        id='addRecipientFab'
                        sx={{
                          '&:hover': { color: WtxColors.ASPHALT },
                          '&.Mui-disabled': { background: WtxColors.CONCRETE },
                          backgroundColor: WtxColors.GROWTH_GREEN,
                          color: 'white'
                        }}
                        size='medium'
                        disabled={selectedRows.length === 0}
                        onClick={() => {
                          setRecipientList(
                            recipientList
                              ? Array.from(new Set(recipientList.concat(selectedRows.map(rl => rl.email))))
                              : selectedRows.map(rl => rl.email)
                          )
                        }}
                      >
                        <AddIcon></AddIcon>
                      </Fab>
                    </span>
                  </Tooltip>
                  <Tooltip slotProps={tooltipStyle} title='Clear all recipients'>
                    <span>
                      <Fab
                        id='removeRecipientFab'
                        sx={{
                          '&:hover': { color: WtxColors.ASPHALT },
                          '&.Mui-disabled': { background: WtxColors.CONCRETE },
                          marginLeft: '5px',
                          backgroundColor: WtxColors.INNOVATION_RED,
                          color: 'white'
                        }}
                        size='medium'
                        disabled={recipientList.length === 0}
                        onClick={() => {
                          setRecipientList([])
                        }}
                      >
                        <DeleteIcon></DeleteIcon>
                      </Fab>
                    </span>
                  </Tooltip>
                </div>
                <Fab
                  id='issueExamFab'
                  sx={{
                    textTransform: 'none',
                    '&:hover': { color: WtxColors.ASPHALT },
                    '&.Mui-disabled': { background: WtxColors.CONCRETE },
                    backgroundColor: WtxColors.IQ_BLUE,
                    color: 'white'
                  }}
                  size='large'
                  variant='extended'
                  disabled={recipientList.length === 0}
                  onClick={() => {
                    setIssueExamModalState({ open: true, recipientList: recipientList })
                  }}
                >
                  Issue Exam
                </Fab>
              </div>
              <RecipientList
                sx={{ marginTop: '15px' }}
                recipientList={recipientList.length !== 0 ? recipientList.join('\n') : 'No Recipients'}
              ></RecipientList>
            </div>
            <CustomDataGrid
              autoHeight
              columns={columns}
              rows={rows ? rows : []}
              isLoading={isLoading}
              onRowClick={rowInfo => {
                setUserModalState({ open: true, user: rowInfo.row })
              }}
              checkboxSelection
              onSelectionModelChange={ids => {
                const selectedIDs = new Set(ids)
                const selectedRows = rows.filter(row => selectedIDs.has(row.id))
                setSelectedRows(selectedRows)
              }}
            />
          </div>
        </>
      }
      drawer={<UsersFilterDrawer setFilter={setFilter} filter={filter} />}
      resetFilter={() => setFilter(DEFAULT_USERS_FILTER)}
    />
  )
}
