import { Card, Grid, Stack } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import FreeformQuestion from './FreeformQuestion'
import MatchingQuestion from './MatchingQuestion'
import MultipleChoiceQuestion from './MultipleChoiceQuestion'
import { ImageText } from './ImageTextField'

// const DEFAULT_ANSWERS = [{ question: { answer: '', id: 0, type: '', prompt: '' } }]

export default function PreviewExamView({ questionsMap, examName, showPoints, examImageInfo }) {
  const [userAnswers, setUserAnswers] = useState([])
  const [rightChoices, setRightChoices] = useState()
  const tempQuestions = useMemo(() => {
    return JSON.parse(JSON.stringify(questionsMap))
  }, [questionsMap])

  useEffect(() => {
    let optionsList = []
    let answerMap = tempQuestions
      ? Object.entries(tempQuestions).map(([key, data]) => {
          if (data.type === 'Freeform') {
            data.answerData = ''
          } else if (data.type === 'Matching') {
            Object.entries(data.answerData).map(([key, ans], index) =>
              optionsList.push({ key: index, answer: { rightImageInfo: ans.rightImageInfo, rightMatch: ans.rightMatch } })
            )
            data.answerData = Object.values(data.answerData).map(e => (e = { ...e, rightMatch: '' }))
          } else if (data.type === 'Multiple Choice') {
            data.answerData = Object.values(data.answerData).map(e => (e = { ...e, isCorrect: false }))
          }
          return { tempQuestions }
        })
      : []
    if (answerMap.length > 0) {
      setUserAnswers(u => [...u, answerMap[0].tempQuestions])
      setRightChoices(optionsList.sort(() => (Math.random() > 0.5 ? 1 : -1)))
    }
  }, [tempQuestions])

  const previewAnswer = (guid, key) => {
    tempQuestions[guid].answerData[key].isCorrect = !tempQuestions[guid].answerData[key].isCorrect
  }

  const setAnswer = (QuestionKey, answer) => {
    let answersMap = Object.entries(userAnswers).map(([key, data]) => {
      if (QuestionKey === key) {
        if (data.type === 'Freeform') {
          data.answerData = answer
        } else if (data.type === 'Matching') {
        } else if (data.type === 'Multiple Choice') {
          data.answerData = Object.entries(data.answerData).map(([key, option]) =>
            key === answer ? (option = { ...option, isCorrect: !option.isCorrect }) : (option = { ...option })
          )
        }
      }
      return userAnswers
    })
    setUserAnswers(answersMap[0])
  }

  return (
    <>
      <ImageText
        image={examImageInfo ? examImageInfo.image : null}
        text={examName}
        isHeading={true}
        position={examImageInfo ? examImageInfo.position : null}
        imageComp={{ type: 'display' }}
      />

      <Stack sx={{ width: '100%', marginBottom: '20px' }}>
        {tempQuestions
          ? Object.entries(tempQuestions)
              .sort(([aKey, aQuestion], [bKey, bQuestion]) => {
                if (aQuestion.number < bQuestion.number) {
                  return -1
                } else {
                  return 1
                }
              })
              .map(([key, question], index) => {
                let leftOptions = []

                if (question.type === 'Matching') {
                  leftOptions = Object.entries(question.answerData).map(([key, ans], index) => ({
                    key: key,
                    answer: { leftImageInfo: ans.leftImageInfo, leftMatch: ans.leftMatch }
                  }))
                }

                return (
                  <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card sx={{ width: '80%', minHeight: '225px', marginTop: '15px' }}>
                      <Grid container sx={{ height: '100%' }}>
                        <Grid item xs={1.5} lg={0.5}></Grid>
                        <Grid item xs={10.5} lg={11.5}>
                          {showPoints ? <h3 style={{ marginLeft: '10px' }}>{question.pointValue} points</h3> : <></>}
                        </Grid>
                        <Grid item xs={1.5} lg={0.5}>
                          <div
                            style={{
                              textAlign: 'right',
                              margin: '10px'
                            }}
                          >
                            <span style={{ fontSize: '1.17em' }}>Q{question.number}</span>
                          </div>
                        </Grid>
                        <Grid item lg={11}>
                          {question.type === 'Freeform' ? (
                            <FreeformQuestion setAnswer={setAnswer} question={question} answerKey={key} />
                          ) : question.type === 'Multiple Choice' ? (
                            <MultipleChoiceQuestion
                              setAnswer={previewAnswer}
                              question={question}
                              guid={key}
                              showPoints={showPoints}
                            />
                          ) : question.type === 'Matching' ? (
                            <MatchingQuestion
                              prompt={question.prompt}
                              imageInfo={question.imageInfo}
                              setAnswer={setAnswer}
                              leftOptions={leftOptions}
                              rightOptions={rightChoices}
                              setRightOptions={setRightChoices}
                              index={index}
                              answerKey={key}
                            />
                          ) : (
                            <div>Error: unknown question type</div>
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                )
              })
          : null}
      </Stack>
    </>
  )
}
