import { SlideUpDialog, CenteredDiv, PrimaryButton, SnackbarVariants } from '@wavetronix/common-components'
import { useMsal } from '@azure/msal-react'
import React, { useState } from 'react'
import FileUploader from '../FileUploader'
import NexusAssetsApi from '../../api/NexusAssetsApi'
import { useSnackbar } from 'notistack'

export default function AddAssetModal({ open, onClose, assetsRefetch }) {
  const { instance, accounts } = useMsal()
  const [file, setFile] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const [isUploading, setIsUploading] = useState(false)
  const [svgPreview, setSvgPreview] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)

  const handleFileChange = e => {
    const file = e.target.files[0]

    if (file && file.type.includes('image/svg+xml')) {
      setImagePreview(null)

      const reader = new FileReader()

      reader.onloadend = () => {
        setSvgPreview(reader.result)
      }

      reader.readAsDataURL(file)

      setFile(file)
    } else if (file && file.type.includes('image')) {
      setSvgPreview(null)

      if (imagePreview) {
        window.URL.revokeObjectURL(imagePreview)
      }
      setImagePreview(window.URL.createObjectURL(file))

      setFile(file)
    }
  }

  function closeModal() {
    assetsRefetch()
    setFile(null)
    setSvgPreview(null)
    if (imagePreview) {
      window.URL.revokeObjectURL(imagePreview)
      setImagePreview(null)
    }
    onClose()
  }

  const uploadAsset = async () => {
    if (file) {
      let formData = new FormData()
      formData.append('file', file)

      await NexusAssetsApi.uploadAsset(instance, accounts, file.type.includes('svg') ? 'image/svg+xml' : 'image/*', formData)
        .then(async response => {
          enqueueSnackbar('Asset upload succeeded', SnackbarVariants.SUCCESS)
        })
        .catch(error => {
          enqueueSnackbar(`Upload failed - ${error.message}`, SnackbarVariants.ERROR)
        })
    }
  }

  return (
    <SlideUpDialog
      id='addAssetModal'
      open={open}
      onClose={closeModal}
      maxWidth={'sm'}
      title={'Upload Asset'}
      actions={
        <PrimaryButton
          id='uploadAssetButton'
          style={{ width: '100px' }}
          onClick={async () => {
            setIsUploading(true)
            await uploadAsset()
            setIsUploading(false)
            closeModal()
          }}
        >
          Upload
        </PrimaryButton>
      }
    >
      {svgPreview ? (
        <CenteredDiv>
          <div style={{ maxHeight: 300, maxWidth: 300, marginTop: 20 }}>
            <img src={svgPreview} alt='Preview' style={{ width: '12em', height: '12em' }} />
          </div>
        </CenteredDiv>
      ) : null}
      {imagePreview ? (
        <CenteredDiv>
          <div style={{ maxHeight: 300, maxWidth: 300, marginTop: 20 }}>
            <img src={imagePreview} alt='Preview' style={{ maxHeight: 300, maxWidth: 300 }} />
          </div>
        </CenteredDiv>
      ) : null}
      <CenteredDiv>
        <FileUploader
          disabled={isUploading}
          style={{ width: '80%', marginTop: 20 }}
          handleFile={handleFileChange}
          fileTypes='image/*'
          prompt={'Choose image...'}
        />
      </CenteredDiv>
    </SlideUpDialog>
  )
}
