// import { ImageText } from './ImageTextField'
// import { Grid } from '@mui/material'
// import ReorderField from './ReorderField'

export default function MatchingQuestion({ prompt, imageInfo, leftOptions, rightOptions, index, answerKey, setAnswer }) {
  // const reorderAnswers = (oldIndex, newIndex) => {
  //   let movingMatchDown = newIndex < oldIndex
  //   let indexes = []
  //   for (let i = 0; i < rightOptions.length; i++) {
  //     indexes.push(i)
  //   }

  //   for (let index of indexes) {
  //     if (movingMatchDown) {
  //       if (index >= newIndex && index <= oldIndex) {
  //         index += 1
  //       }
  //     } else {
  //       if (index <= newIndex && index > oldIndex) {
  //         console.log(index)
  //         index -= 1
  //       }
  //     }
  //   }
  //   indexes[oldIndex] = newIndex
  //   let result = indexes.map(i => rightOptions[i])

  //   console.log(indexes)
  //   console.log(rightOptions)
  //   console.log(result)

  //   // rightOptions.map(match => {
  //   //   console.log(match)
  //   // })
  //   // console.log(`moving ${oldIndex} to ${newIndex}`)
  //   // let matchesLength = rightOptions.length
  //   // if (newIndex > matchesLength) {
  //   //   newIndex = matchesLength
  //   // }

  //   // rightOptions.splice(oldIndex - 1, 1).map(match => {
  //   //   console.log(match)
  //   // })
  //   // rightOptions.splice(newIndex, 0, 'match').map(match => {
  //   //   console.log(match)
  //   // })

  //   // setQuestionsMap({ ...questionsMap })
  // }

  return (
    <>
      {' '}
      <div style={{ width: '100%', padding: '10px' }}>
        <h3>Matching questions are under construction</h3>
        {/* 
        <h3>{question.pointValue} points</h3>
        <ImageText
          image={imageInfo ? imageInfo.image : null}
          text={prompt}
          isHeading={true}
          position={imageInfo ? imageInfo.position : null}
          imageComp={{ type: 'display' }}
        />
        <div key={index} style={{ display: 'flex' }}>
          <Grid container sx={{ minWidth: '100%' }}>
            <Grid item lg={4} sx={{ marginRight: '36px' }}>
              <div style={{ width: 'auto' }}>
                {leftOptions
                  ? leftOptions.map((opt, index) => (
                      <ImageText
                        key={index}
                        image={opt.answer.leftImageInfo ? opt.answer.leftImageInfo.image : null}
                        text={opt.answer.leftMatch}
                        position={opt.answer.leftImageInfo ? opt.answer.leftImageInfo.position : null}
                        imageComp={{ type: 'display' }}
                      />
                    ))
                  : []}
              </div>
            </Grid>
            <Grid item lg={6}>
              <div style={{ width: '50%' }}>
                {rightOptions
                  ? rightOptions.map((opt, index) => (
                      <>
                        <ReorderField number={index} reorder={reorderAnswers}></ReorderField>
                        <ImageText
                          key={index}
                          image={opt.answer.rightImageInfo ? opt.answer.rightImageInfo.image : null}
                          text={opt.answer.rightMatch}
                          position={opt.answer.rightImageInfo ? opt.answer.rightImageInfo.position : null}
                          imageComp={{ type: 'display' }}
                        />
                      </>
                    ))
                  : []}
              </div>
            </Grid>
          </Grid>
        </div> */}
      </div>
    </>
  )
}
