import { acquireAccessToken } from '@wavetronix/common-components'
import { env } from '../index.js'
import axios from 'axios'

let instance = null

class UsersApi {
  getUsers = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/users`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getMe = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/users/me`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new UsersApi()
  }
  return instance
}

export default getInstance()
